/* EditorStyles.css

.imageWrapper {
    position: relative;
    display: inline-block;
  }
  
  .resizableImage {
    display: block;
  }
  
  .resizeHandle {
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: ew-resize;
    background: rgba(0, 0, 0, 0.5);
  }
  .froala-editor img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  

  /* Apply styles to images in both editor and preview */
.fr-view img, .preview img {
  display: block; /* Ensure images are treated as block elements */
  margin: 0 auto; /* Center alignment */
  max-width: 100%; /* Ensure responsiveness */
}

/* Alignment classes */
.fr-view img.align-left, .preview img.align-left {
  float: left;
  margin-right: 10px; /* Optional spacing */
}

.fr-view img.align-right, .preview img.align-right {
  float: right;
  margin-left: 10px; /* Optional spacing */
}

.fr-view img.align-center, .preview img.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto; /* Center image */
}


.class1 {
  text-decoration: underline;
}

.class2 {
  color: blue;
} 